import {
    MissingInfoCategory,
    MissingInfoCategoryDetail,
    MissingInfoCategoryWithDetails,
    SupportingDocumentType,
} from '../../../domain/values';
import { Chip, ChipType, GroupedData, Option } from '../../atoms';
import { useModuleTranslation } from '../../utils/useModuleTranslation';
import { useEffect, useRef, useState } from 'react';
import { ClaimRequestType } from '../../../domain/entities';
import { defaultCountryCode } from '../../../domain/values/CountryCode';

export interface MissingInfoCategorySelectorProps {
    missingInfoCategoriesAlreadySelected: MissingInfoCategory[];
    onMissingInfoCategorySelectedHandler: (missingInfoCategoryWithDetails: MissingInfoCategoryWithDetails) => void;
    onMissingInfoCategoryRemoveHandler: (missingInfoCategory: MissingInfoCategory) => void;
    options: SupportingDocumentType[];
    claimRequestType: ClaimRequestType;
    countryCode?: string;
    petName?: string;
}

const MissingInfoCategorySelector = ({
    missingInfoCategoriesAlreadySelected,
    onMissingInfoCategorySelectedHandler,
    onMissingInfoCategoryRemoveHandler,
    options,
    claimRequestType,
    countryCode = defaultCountryCode,
    petName,
}: MissingInfoCategorySelectorProps) => {
    const controlledTemplates = ['VET_REPORT', 'CLINICAL_HISTORY', 'INVOICE', 'VET_FORM'];
    const [selectedCategoryWithDetails, setSelectedCategoryWithDetails] =
        useState<MissingInfoCategoryWithDetails | null>(null);
    const { t } = useModuleTranslation();
    const defaultMissingInfoCategories = () => {
        if (missingInfoCategoriesAlreadySelected.length > 0)
            return options
                .map((option) => option as MissingInfoCategory)
                .filter((it) => {
                    const hasOtherSelected = missingInfoCategoriesAlreadySelected?.some(
                        (selected) => selected && selected !== SupportingDocumentType.VET_FORM
                    );
                    return !(hasOtherSelected && it === SupportingDocumentType.VET_FORM);
                });
        else return options.map((option) => option as MissingInfoCategory);
    };
    const templatesGrouped = t(`${claimRequestType.toString().toLowerCase()}.templates`, {
        ns: 'missing_info',
        lng: countryCode ? countryCode.toLowerCase() : defaultCountryCode.toLowerCase(),
        returnObjects: true,
        petName,
    }) as GroupedData<string>[];

    const getTemplatesBySupportingDocument = (category: MissingInfoCategory): Option<string>[] => {
        const result = templatesGrouped.find((it) => it.group.toUpperCase() == category);

        if (!result)
            return templatesGrouped
                .filter((template) => !controlledTemplates.includes(template.group))
                .flatMap((it) => it.options);

        return result.options;
    };

    const previousMissingInfoCategoryWithDetailsSelected = useRef<MissingInfoCategoryWithDetails | null>(null);
    const [missingInfoOptions, setMissingInfoOptions] = useState<MissingInfoCategory[]>(defaultMissingInfoCategories());
    const [missingInfoTemplates, setMissingInfoTemplates] = useState<Option<string>[]>([]);

    useEffect(() => {
        if (selectedCategoryWithDetails && selectedCategoryWithDetails.missingInfoCategory) {
            setMissingInfoOptions([selectedCategoryWithDetails.missingInfoCategory]);
            onMissingInfoCategorySelectedHandler(selectedCategoryWithDetails);
        } else if (previousMissingInfoCategoryWithDetailsSelected.current) {
            setMissingInfoOptions(defaultMissingInfoCategories());
            onMissingInfoCategoryRemoveHandler(
                previousMissingInfoCategoryWithDetailsSelected.current.missingInfoCategory
            );
        }
    }, [selectedCategoryWithDetails]);

    useEffect(() => {
        if (selectedCategoryWithDetails && selectedCategoryWithDetails.missingInfoCategory) {
            setMissingInfoOptions([selectedCategoryWithDetails.missingInfoCategory]);
        } else if (previousMissingInfoCategoryWithDetailsSelected.current) {
            setMissingInfoOptions(defaultMissingInfoCategories());
        }
    }, [missingInfoCategoriesAlreadySelected]);

    const handleMissingInfoClicked = (description) => {
        const missingInfoCategoryDescription = description as MissingInfoCategory;

        if (selectedCategoryWithDetails == null) {
            setSelectedCategoryWithDetails((prevState) => {
                previousMissingInfoCategoryWithDetailsSelected.current = prevState;
                return {
                    missingInfoCategory: missingInfoCategoryDescription,
                    detail: [],
                };
            });
            setMissingInfoTemplates(getTemplatesBySupportingDocument(missingInfoCategoryDescription));
        } else {
            setMissingInfoTemplates([]);
            setSelectedCategoryWithDetails((prevState) => {
                previousMissingInfoCategoryWithDetailsSelected.current = prevState;
                return null;
            });
        }
    };

    const handleTemplateSelector = (template: Option<string>) => {
        if (selectedCategoryWithDetails && selectedCategoryWithDetails.missingInfoCategory) {
            const missingInfoDetail: MissingInfoCategoryDetail = { label: template.label, value: template.value };
            const prevDetails = selectedCategoryWithDetails?.detail || [];

            const updatedDetails = prevDetails.includes(missingInfoDetail)
                ? prevDetails.filter((item) => item !== missingInfoDetail)
                : [...prevDetails, missingInfoDetail];

            setSelectedCategoryWithDetails((prevState) => {
                if (prevState && prevState.missingInfoCategory) {
                    return {
                        missingInfoCategory: prevState.missingInfoCategory,
                        detail: updatedDetails,
                    };
                }

                return prevState;
            });
        }
    };

    return (
        <>
            <section>
                <span className="label">{t(`missing_info.which`)}</span>
                <div className="missing-info-category-selector">
                    {missingInfoOptions.map((description) => (
                        <Chip
                            key={description}
                            type={ChipType.OPTION}
                            text={t(`missing_info.type.${description}`)}
                            checked={
                                selectedCategoryWithDetails?.detail &&
                                missingInfoOptions.includes(selectedCategoryWithDetails.missingInfoCategory)
                            }
                            onClick={() => handleMissingInfoClicked(description)}
                        />
                    ))}
                </div>
            </section>
            {missingInfoTemplates.length > 0 && (
                <section>
                    <span className="label">{t(`missing_info.what`)}</span>
                    <div className="missing-info-category-selector">
                        {missingInfoTemplates.map((option) => (
                            <Chip
                                key={option.label}
                                type={ChipType.OPTION}
                                text={option.label}
                                checked={selectedCategoryWithDetails?.detail
                                    .flatMap((it) => it.label)
                                    .includes(option.label)}
                                onClick={() => handleTemplateSelector(option)}
                            />
                        ))}
                    </div>
                </section>
            )}
        </>
    );
};

export default MissingInfoCategorySelector;
